@use "@angular/material" as mat;
@import "./material.scss";

.color-accent-800 {
  color: mat.get-color-from-palette($accent, 800) !important;
}

.color-accent-400 {
  color: mat.get-color-from-palette($accent, 400) !important;
}

.color-primary-400 {
  color: mat.get-color-from-palette($primary, 400) !important;
}

.color-warn {
  color: mat.get-color-from-palette($warn, 500) !important;
}

.color-warn-400 {
  color: mat.get-color-from-palette($warn, 400) !important;
}

.color-warn-300 {
  color: mat.get-color-from-palette($warn, 300) !important;
}

.highlight-bg {
  background-color: mat.get-color-from-palette($accent, 300);
}

.highlight-fullmatch-bg {
  background-color: mat.get-color-from-palette($warn, 300);
}

.color-green {
  color: #32cd32;
}

.color-black {
  color: #000000;
}