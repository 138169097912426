@use "@angular/material" as mat;
@use "@ng-matero/extensions" as mtx;

@include mat.core();
@import "../../shared/src/lib/styles/material.scss";

@import "../../shared/src/lib/shared.scss";
@import "./styles-global.scss";
@import "./styles-colors.scss";
@import "./styles-global-mat-overwrites.scss";
@import "./styles-font.scss";
@import "./styles-table.scss";

@import "https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined";
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap");

@import "ag-grid-community/styles/ag-grid.css";
@import "ag-grid-community/styles/ag-theme-alpine.css";

// Typography
// Use the default configuration.
$my-typography: mat.define-typography-config();
@include mat.typography-hierarchy($my-typography);

$light-theme: mat.define-light-theme(
  (
    color: (
      primary: $primary,
      accent: $accent,
      warn: $warn,
    ),
    typography: mat.define-typography-config(),
    density: 0,
  )
);

@include mat.all-component-themes($light-theme);
@include mtx.all-component-themes($light-theme);

body {
  --light-bg-hover: #f7f7f7;
  --light-bg-selection: #e0e0e0;
}

html,
body {
  font-family: Roboto, "Helvetica Neue", sans-serif;

  margin: 0;

  height: 100vh;
  width: 100vw;
}

.ag-theme-alpine {
  --ag-grid-size: 4px;
  --ag-list-item-height: 20px;
  //--ag-font-size: 10px;
}


.ag-row-red {
  background-color: red !important;
}
