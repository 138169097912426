.cursor-pointer {
  cursor: pointer;
}

.pointer-events-none {
  pointer-events: none;
}

.spinner {
  animation: spinner 0.6s linear infinite;
}

@keyframes spinner {
  100% {
    transform: rotate(360deg);
  }
}

.text-align-right {
  text-align: right;
}

.overflow-y-auto {
  overflow-y: auto;
}

.height-100 {
  height: 100%;
}

.width-100 {
  width: 100%;
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

.white-space-nowrap {
  white-space: nowrap;
}

.fill {
  font-variation-settings: "FILL" 1, "wght" 400, "GRAD" 0, "opsz" 24 !important;
}

.prevent-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.opacity-70 {
  opacity: 0.7;
}

.opacity-60 {
  opacity: 0.6;
}

.opacity-50 {
  opacity: 0.5;
}

.opacity-40 {
  opacity: 0.4;
}

.opacity-30 {
  opacity: 0.3;
}