@import "styles/variables.scss";
@import "styles/general.scss";
@import "styles/font.scss";
@import "styles/flex.scss";
@import "styles/grid.scss";
@import "styles/color.scss";
@import "styles/overflow.scss";
@import "styles/density.scss";
@import "styles/table.scss";
@import "styles/padding.scss";

.mt-huge {
  margin-top: $huge;
}

.mt-large {
  margin-top: $large;
}

.mt-normal {
  margin-top: $normal;
}

.mt-small {
  margin-top: $small;
}

.mt-tiny {
  margin-top: $tiny;
}

.mr-normal {
  margin-right: $normal;
}

.mr-small {
  margin-right: $small;
}

.mr-tiny {
  margin-right: $tiny;
}

.mb-huge {
  margin-bottom: $huge;
}

.mb-large {
  margin-bottom: $large;
}

.mb-normal {
  margin-bottom: $normal;
}

.mb-small {
  margin-bottom: $small;
}

.mb-tiny {
  margin-bottom: $tiny;
}

.ml-normal {
  margin-left: $normal;
}

.ml-small {
  margin-left: $small;
}

.ml-tiny {
  margin-left: $tiny;
}

.column-gap-tiny {
  column-gap: $tiny;
}

.column-gap-small {
  column-gap: $small;
}

.column-gap-normal {
  column-gap: $normal;
}

.column-gap-large {
  column-gap: $large;
}

.opacity-60 {
  opacity: 0.6;
}

.opacity-50 {
  opacity: 0.5;
}

.primary-color {
  color: var(--primary-color);
}

.nowrap {
  white-space: nowrap;
}

.height-100 {
  height: 100%;
}

.width-100 {
  width: 100%;
}
