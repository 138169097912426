@use "@angular/material" as mat;

.small {
  font-size: 12px;
}

// https://m2.material.io/develop/web/supporting/density
// https://stackoverflow.com/questions/76186036/angular-material-changing-the-density-doesnt-affect-the-html-element

.button-density-1 {
  @include mat.button-density(-1);
}

.button-density-2 {
  @include mat.button-density(-2);
}

.button-density-3 {
  @include mat.button-density(-3);
}
