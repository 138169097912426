.font-weight-500 {
  font-weight: 500 !important;
}

.font-weight-600 {
  font-weight: 600 !important;
}

.font-size-20 {
  font-size: 20px;
}

.font-size-18 {
  font-size: 18px;
}

.font-size-16 {
  font-size: 16px;
}

.font-size-14 {
  font-size: 14px;
}

.font-size-12 {
  font-size: 12px;
}

.line-height-14 {
  line-height: 14px;
}

.ellipsis {
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
