@use "@angular/material" as mat;
@import "../../shared/src/lib/styles/material.scss";

.active-select-bg {
  background-color: var(--light-bg-selection) !important;
}

.text-highlight-bg {
  background-color: mat.get-color-from-palette($accent, 300);
}

.text-highlight-fullmatch-bg {
  background-color: mat.get-color-from-palette($warn, 300);
}

.color-accent-500 {
  color: mat.get-color-from-palette($accent, 500);
}

.color-warn-500 {
  color: mat.get-color-from-palette($warn, 500);
}
