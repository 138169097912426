@import "./variables.scss";

.flex-row {
  display: flex;
  flex-direction: row;

  &.gap-tiny {
    column-gap: $tiny;
  }

  &.gap-small {
    column-gap: $small;
  }

  &.gap-normal {
    column-gap: $normal;
  }

  &.gap-large {
    column-gap: $large;
  }

  &.gap-large-x2 {
    column-gap: calc($large * 2);
  }
}

.flex-row-reverse {
  display: flex;
  flex-direction: row-reverse;

  &.gap-tiny {
    column-gap: $tiny;
  }

  &.gap-small {
    column-gap: $small;
  }

  &.gap-normal {
    column-gap: $normal;
  }

  &.gap-large {
    column-gap: $large;
  }

  &.gap-large-x2 {
    column-gap: calc($large * 2);
  }
}

.flex-column {
  display: flex;
  flex-direction: column;

  &.gap-tiny {
    row-gap: $tiny;
  }

  &.gap-small {
    row-gap: $small;
  }

  &.gap-normal {
    row-gap: $normal;
  }

  &.gap-large {
    row-gap: $large;
  }
}

.flex-column-reverse {
  display: flex;
  flex-direction: column-reverse;
  &.gap-tiny {
    row-gap: $tiny;
  }

  &.gap-small {
    row-gap: $small;
  }

  &.gap-normal {
    row-gap: $normal;
  }

  &.gap-large {
    row-gap: $large;
  }
}

.align-items-center {
  align-items: center;
}

.align-items-end {
  align-items: end;
  align-items: flex-end;
}

.justify-content-space-between {
  justify-content: space-between;
}

.justify-content-space-evenly {
  justify-content: space-evenly;
}

.justify-content-center {
  justify-content: center;
}

.align-self-start {
  align-self: flex-start;
}

.align-self-center {
  align-self: center;
}

.align-self-end {
  align-self: flex-end;
}

.justify-self-center {
  justify-self: center;
}

.flex-grow-1 {
  flex-grow: 1;
}