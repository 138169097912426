@use "@angular/material" as mat;
@import "../../../../shared/src/lib/styles/material.scss";

.generic-table {
    border-spacing: 2px;
    th {
      font-weight: 500;
      padding: 4px 8px;
      text-align: left;
      background-color: mat.get-color-from-palette($primary, 300);
      color: #ffffff;
    }
  
    td {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.87);
      padding: 4px 8px;
      text-align: left;
      background-color: mat.get-color-from-palette($primary, 50);
    }
  
    tr {
      &:hover {
        td {
          background-color: mat.get-color-from-palette($primary, 100);
        }
      }
    }
  }